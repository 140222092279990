import { Button } from 'react-bootstrap'
import * as COLORS from '../constants/colors'
import Lottie from 'react-lottie'
import LinkLottie from '../assets/lotties/link.json'
import CoinLottie from '../assets/lotties/coin.json'
import CommunityLottie from '../assets/lotties/community.json'
import GrowthLottie from '../assets/lotties/growth.json'
import Fade from 'react-reveal/Fade'
import LandingPhoto from '../assets/photos/landingPhoto.jpg'
import CreatorVideo from '../assets/videos/creator.mp4'
import { I18n } from 'i18n-js'
import { useMediaQuery } from 'react-responsive'

const Home = (props) => {
	const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' })
	const linkOptions = {
		loop: true,
		autoplay: true,
		animationData: LinkLottie,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid meet'
		}
	}
	const coinOptions = {
		loop: true,
		autoplay: true,
		animationData: CoinLottie,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid meet'
		}
	}
	const communityOptions = {
		loop: true,
		autoplay: true,
		animationData: CommunityLottie,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid meet'
		}
	}
	const growthOptions = {
		loop: true,
		autoplay: true,
		animationData: GrowthLottie,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid meet'
		}
	}

	const i18n = new I18n({
		ko: {
			landingSubHeader: 'Creator과 Fan과 우연 같은 만남',
			landingButton: '크리에이터로 가입하기',
			companyDescriptionHeader: 'Wooyeon이 뭐예요?',
			companyDescriptionText:
				'Wooyeon은 한국 크리에이터와 KPop 크리에이터들이 독점 콘텐츠와 특전의 대가로 팬들로부터 매달 수입을 올릴 수 있는 멤버십 플랫폼입니다.',
			numbersHeader: '크리에이터',
			numbersSubHeader: '거절할 수 없는 제안',
			numbersParagraph:
				'Wooyeon은 시장에 나와 있는 다른 대안들보다 훨씬 저렴한 크리에이터 수익 창출 솔루션입니다. 저희는 초저가 요금으로 여러분의 노고에 보답하기 위해 노력합니다.',
			numbersCardText1: 'YouTube의 멤버십 서비스보다 저렴함',
			numbersCardText2: '이익은 크리에이터 의해 보관됨',
			numbersCardText3: '앱 스토어 및 구글 플레이 스토어 수수료에서 절약됨',
			specialOfferHeader: '독점 제안',
			specialOfferParagraph:
				'초기 및 상위 크리에이터를 위해 처음 6개월 이상 수익의 100%를 유지할 수 있는 특별 제안이 있습니다.',
			benefitsHeader: '멤버십 혜택',
			benefitsParagraph1:
				'Wooyeon은 팬들이 진정으로 원하는 것을 바탕으로 크리에이터들이 자유럽게 다양한 멤버십 혜택을 제공할 수 있도록 합니다.',
			benefitsParagraph2: '다음은 당신이 제공할 수 있는 몇 가지 가능한 멤버십 혜택입니다.',
			benefitsParagraph3: '회원 전용 콘텐츠 (글, 사진, 영상)',
			benefitsParagraph4: '유튜브 동영상 끝에 후원자 언급',
			benefitsParagraph5: '콘텐츠에 대한 조기 액세스 (예: 1-2일 조기 액세스)',
			benefitsParagraph6: '비하인드 사진 & 영상',
			benefitsParagraph7: '크리에이터와 회원 전용 단톡방',
			tiersHeader: '3가지 가격 계층',
			tiersParagraph:
				'크리에이터는 팬들에게 제공할 3가지 가격 계층(미국 달러) 중에서 선택할 수 있습니다. 이것은 당신이 크리에이터로서 Wooyeon에서 제공하는 멤버십 혜택에 대해 직접 가격을 정할 수 있게 해줍니다.',
			tierCardText1: '엔트리 계층',
			tierCardText2: '중간 계층',
			tierCardText3: 'VIP 계층',
			communityHeader: 'Wooyeon 커뮤니티',
			communityDescription:
				'한국 최고의 크리에이터 커뮤니티에 가입하여 콘텐츠 크리에이터로서의 수입을 늘리기 위해 Wooyeon을 활용하세요.',
			communityRightText:
				'Wooyeon을 사용하면 슈퍼팬과 콘텐츠를 보다 효과적으로 수익화할 수 있으므로 진정으로 중요한 것, 즉 콘텐츠 제작에 집중할 수 있습니다.',
			supportNetworkHeader: '우리의 지원 네트워크',
			belowSupportNetworkCardText1: '공식 페이 파트너',
			belowSupportNetworkCardText2: '아이비리그 학교에서 설립',
			reminderHeader: '크리에이터 수익 창출이 쉬워졌습니다.',
			reminderStatement: '크리에이터 게임을 한 단계 업그레이드하십시오.',
			reminderButton: '크리에이터: 데모 신청하기',
			footerText1: '© 2022 Wooyeon 팀 (Louis Chung and Wenxuan Chen). 무단 전재 금지.',
			footerText2:
				'Wooyeon은 이 웹사이트를 참조하고, Wooyeon 팀은 Wooyeon(Louis Chung 및 Wenxuan Chen)의 소유자를 참조합니다.',
			footerText3:
				'우리 Wooyeon 팀은 어떠한 사전 통지 없이 언제든지 본 웹 사이트의 정보를 변경할 수 있는 권리를 보유합니다.',
			footerText4:
				'Wooyeon에 대한 질문이나 우려 사항 또는 관련 정보는 support@wooyeon.me 으로 문의하십시오.',
			tos: '서비스 약관',
			pp: '개인 정보 보호 정책'
		},
		en: {
			landingSubHeader: 'Magical encounter between creators and their superfans. ',
			landingButton: 'Join as a Creator',
			companyDescriptionHeader: 'What is Wooyeon?',
			companyDescriptionText:
				'Wooyeon is a membership platform where Korean and KPop creators can earn a monthly income from their fans in return for exclusive content and privileges.',
			numbersHeader: 'Creators',
			numbersSubHeader: 'An Offer You Cannot Reject',
			numbersParagraph:
				'	Wooyeon is a creator monetization solution much cheaper than other alternatives on the market. With our ultra-cheap rates, we strive to provide as much ₩ for your hard work.',
			numbersCardText1: "Cheaper than YouTube's Membership Service",
			numbersCardText2: 'of profit is kept by the creator',
			numbersCardText3: 'Saved from App Store & Google Play Store fees',
			specialOfferHeader: 'Exclusive Offer',
			specialOfferParagraph:
				'For our early and top creators, we have a special deal where for the first 6+ months, you can keep 100% of your profits.',
			benefitsHeader: 'Membership Benefits',
			benefitsParagraph1:
				'Wooyeon allows creators to be flexible and offer different membership benefits on their own terms, based on what fans truly want.',
			benefitsParagraph2: 'Here are some possible membership benefits that you can provide.',
			benefitsParagraph3: 'Exclusive Member-Only content (text, images, videos)',
			benefitsParagraph4: 'Shoutout to patrons at the end of YouTube videos',
			benefitsParagraph5: 'Early access to content (e.g. 1-2 days early access)',
			benefitsParagraph6: 'Behind the scenes photos & Bloopers videos',
			benefitsParagraph7: 'Private Members-Only Group Chat with Creator',
			tiersHeader: '3 Different Price Tiers',
			tiersParagraph:
				'Creators can choose between 3 different price tiers (USD) to offer to their fans. This allows you as the creator to set your own rate on the membership benefits you provide on Wooyeon.',
			tierCardText1: 'Entry Tier',
			tierCardText2: 'Mid Tier',
			tierCardText3: 'VIP Tier',
			communityHeader: 'Wooyeon community',
			communityDescription:
				'Join our community of top Korean creators and start using Wooyeon to boost your income as a content creator.',
			communityRightText:
				'Wooyeon lets you more effectively monetize your superfans and your content so you can just focus on what truly matters - content creation.',
			supportNetworkHeader: 'Our Support Network',
			belowSupportNetworkCardText1: 'Official Payment Partner',
			belowSupportNetworkCardText2: 'Founded at',
			reminderHeader: 'Creator Monetization made easy.',
			reminderStatement: 'Take your creator game to the next level.',
			reminderButton: 'Creators: Request a Demo',
			footerText1: '© 2022 Wooyeon Team (Louis Chung and Wenxuan Chen). All Rights Reserved.',
			footerText2:
				'Wooyeon refers to this website, and Wooyeon team refers to the creators and owners of Wooyeon (Louis Chung and Wenxuan Chen).',
			footerText3:
				'We, the Wooyeon team, reserve the right to change any information on this website at any time, without any prior notice.',
			footerText4:
				'If you any questions or concerns about Wooyeon or any related information, please contact us at support@wooyeon.me',
			tos: 'Terms of Service',
			pp: 'Privacy Policy'
		}
	})
	i18n.enableFallback = true
	i18n.locale = navigator.language

	const openForm = async () => {
		if (navigator.language === 'ko') {
			window.open('https://forms.gle/kMUpMK5RrUoQEyfY7', '_blank')
		} else {
			window.open('https://forms.gle/zMArv2ZWB9Tfx8Qh8', '_blank')
		}
	}

	if (isDesktop) {
		return (
			<div style={styles.screen}>
				<div style={styles.landing}>
					<h2 style={styles.logo}>Wooyeon</h2>
					<div style={styles.landingLeft}>
						{i18n.locale === 'ko' ? (
							<h1 style={styles.landingHeader}>
								<span style={styles.landingHeaderSpecial}>우</span>아{' '}
								<span style={styles.landingHeaderSpecial}>연</span>예인이다!
							</h1>
						) : (
							<h1 style={styles.landingHeader}>Your favorite K-creator!</h1>
						)}
						<h1 style={styles.landingSubHeader}>{i18n.t('landingSubHeader')}</h1>
						<Button style={styles.landingButton} onClick={openForm}>
							{i18n.t('landingButton')}
						</Button>
					</div>
				</div>
				<Fade bottom>
					<div style={styles.companyDescriptionSection}>
						<h1 style={styles.companyDescriptionHeader}>{i18n.t('companyDescriptionHeader')}</h1>
						<p style={styles.companyDescriptionText}>{i18n.t('companyDescriptionText')}</p>
						<div style={styles.linkContainer}>
							<h2 style={styles.linkText}>Fan</h2>
							<Lottie
								options={linkOptions}
								height={'100%'}
								width={'100%'}
								style={{ alignSelf: 'center' }}
								isClickToPauseDisabled={true}
							/>
							<h2 style={styles.linkText}>Creator</h2>
						</div>
					</div>
				</Fade>
				<div style={styles.numbersSection}>
					<div style={styles.numbersSectionLeft}>
						<h4 style={styles.numbersHeader}>{i18n.t('numbersHeader')}</h4>
						<h1 style={styles.numbersSubHeader}>{i18n.t('numbersSubHeader')}</h1>
						<p style={styles.numbersParagraph}>{i18n.t('numbersParagraph')}</p>
					</div>
					<div style={styles.numbersSectionRight}>
						<Fade right cascade>
							<div className="shadow-lg" style={styles.numbersCard}>
								<p style={styles.numbersCardValue}>3x</p>
								<p style={styles.numbersCardText}>{i18n.t('numbersCardText1')}</p>
							</div>
							<div className="shadow-lg" style={styles.numbersCard}>
								<p style={styles.numbersCardValue}>90%</p>
								<p style={styles.numbersCardText}>{i18n.t('numbersCardText2')}</p>
							</div>
							<div className="shadow-lg" style={styles.numbersCard}>
								<p style={styles.numbersCardValue}>30%</p>
								<p style={styles.numbersCardText}>{i18n.t('numbersCardText3')}</p>
							</div>
						</Fade>
					</div>
				</div>
				<div style={styles.specialOfferSection}>
					<div style={styles.specialOfferSectionLeft}>
						<h1 style={styles.specialOfferHeader}>{i18n.t('specialOfferHeader')}</h1>
						<p style={styles.specialOfferParagraph}>{i18n.t('specialOfferParagraph')}</p>
					</div>
					<div style={styles.specialOfferSectionRight}>
						<Lottie
							options={coinOptions}
							height={'80%'}
							width={'80%'}
							isClickToPauseDisabled={true}
						/>
					</div>
				</div>
				<div style={styles.benefitsSection}>
					<div style={styles.benefitsSectionLeft}>
						<h1 style={styles.benefitsHeader}>{i18n.t('benefitsHeader')}</h1>
						<p style={styles.benefitsParagraph}>{i18n.t('benefitsParagraph1')}</p>
						<p style={styles.benefitsParagraph}>{i18n.t('benefitsParagraph2')}</p>
						<li style={styles.benefitsParagraph}>{i18n.t('benefitsParagraph3')}</li>
						<li style={styles.benefitsParagraph}>{i18n.t('benefitsParagraph4')}</li>
						<li style={styles.benefitsParagraph}>{i18n.t('benefitsParagraph5')}</li>
						<li style={styles.benefitsParagraph}>{i18n.t('benefitsParagraph6')}</li>
						<li style={styles.benefitsParagraph}>{i18n.t('benefitsParagraph7')}</li>
					</div>
					<div style={styles.benefitsSectionRight}>
						<video width="50%" height="50%" autoPlay loop muted style={{ borderRadius: 25 }}>
							<source src={CreatorVideo} type="video/mp4" />
						</video>
					</div>
				</div>
				<div style={styles.tiersSection}>
					<div style={styles.tiersSectionLeft}>
						<h1 style={styles.tiersHeader}>{i18n.t('tiersHeader')}</h1>
						<p style={styles.tiersParagraph}>{i18n.t('tiersParagraph')}</p>
					</div>
					<div style={styles.tiersSectionRight}>
						<Fade right cascade>
							<div className="shadow-lg" style={styles.tierCard}>
								<p style={styles.tierCardValue}>$5</p>
								<p style={styles.tierCardText}>{i18n.t('tierCardText1')}</p>
							</div>
							<div className="shadow-lg" style={styles.tierCard}>
								<p style={styles.tierCardValue}>$10</p>
								<p style={styles.tierCardText}>{i18n.t('tierCardText2')}</p>
							</div>
							<div className="shadow-lg" style={styles.tierCard}>
								<p style={styles.tierCardValue}>$25</p>
								<p style={styles.tierCardText}>{i18n.t('tierCardText3')}</p>
							</div>
						</Fade>
					</div>
				</div>
				<div style={styles.communitySection}>
					<h1 style={styles.communityHeader}>{i18n.t('communityHeader')}</h1>
					<p style={styles.communityDescription}>{i18n.t('communityDescription')}</p>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-evenly'
						}}
					>
						<div style={{ width: '45%', borderRadius: 15, background: 'white' }}>
							<Lottie
								options={communityOptions}
								height={'90%'}
								width={'90%'}
								isClickToPauseDisabled={true}
							/>
						</div>
						<div style={{ width: '45%', display: 'flex', justifyContent: 'flex-end' }}>
							<p style={styles.communityRightText}>{i18n.t('communityRightText')}</p>
						</div>
					</div>
				</div>
				<div style={styles.supportNetworkSection}>
					<h1 style={styles.supportNetworkHeader}>{i18n.t('supportNetworkHeader')}</h1>
					<div style={styles.supportNetworkCardContainer}>
						<Fade bottom cascade>
							<img
								src={require('../assets/photos/VIP-X.png')}
								alt="VIPX"
								style={styles.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/VIP-C.png')}
								alt="VIPC"
								style={styles.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/VentureLab.png')}
								alt="VLAB"
								style={styles.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/10KE.png')}
								alt="10KE"
								style={styles.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/WhartonEntrepreneurship.png')}
								alt="WhartonEntrepreneurship"
								style={styles.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/InnovationFund.png')}
								alt="InnovationFund"
								style={styles.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/WeissFund.png')}
								alt="WeissFund"
								style={styles.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/JamesJooJin.jpg')}
								alt="JamesJooJin"
								style={styles.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/EALC.png')}
								alt="EALC"
								style={styles.supportNetworkCard}
								className="shadow"
							/>
						</Fade>
					</div>
				</div>
				<div style={styles.belowSupportNetworkSection}>
					<Fade left cascade>
						<div style={styles.belowSupportNetworkCard}>
							<h2 style={styles.belowSupportNetworkCardText}>
								{i18n.t('belowSupportNetworkCardText1')}
							</h2>
							<img src={require('../assets/photos/Stripe.png')} alt="Stripe" width={300} />
						</div>
					</Fade>
					<Fade right cascade>
						<div style={styles.belowSupportNetworkCard}>
							<h2 style={styles.belowSupportNetworkCardText}>
								{i18n.t('belowSupportNetworkCardText2')}
							</h2>
							<img src={require('../assets/photos/UPenn.png')} alt="UPenn" width={300} />
						</div>
					</Fade>
				</div>
				<div style={styles.reminderSection}>
					<div style={styles.reminderSectionLeft}>
						<h1 style={styles.reminderLogo}>Wooyeon</h1>
						<p style={styles.reminderHeader}>{i18n.t('reminderHeader')}</p>
						<h1 style={styles.reminderStatement}>{i18n.t('reminderStatement')}</h1>
						<Button style={styles.reminderButton} onClick={openForm}>
							{i18n.t('reminderButton')}
						</Button>
					</div>
					<div style={styles.reminderSectionRight}>
						<Lottie
							options={growthOptions}
							height={'80%'}
							width={'80%'}
							isClickToPauseDisabled={true}
						/>
					</div>
				</div>
				<div style={styles.footer}>
					<div>
						<p style={styles.footerText}>{i18n.t('footerText1')}</p>
						<p style={styles.footerText}>{i18n.t('footerText2')}</p>
						<p style={styles.footerText}>{i18n.t('footerText3')}</p>
						<div style={styles.documentContainer}>
							<p style={styles.tos}>{i18n.t('tos')}</p>
							<p style={styles.pp}>{i18n.t('pp')}</p>
						</div>
						<p style={styles.footerText}>{i18n.t('footerText4')}</p>
					</div>
				</div>
			</div>
		)
	} else {
		return (
			<div style={stylesM.screen}>
				<div style={stylesM.landing}>
					<h2 style={stylesM.logo}>Wooyeon</h2>
					<div style={stylesM.landingLeft}>
						{i18n.locale === 'ko' ? (
							<h1 style={stylesM.landingHeader}>
								<span style={stylesM.landingHeaderSpecial}>우</span>아{' '}
								<span style={stylesM.landingHeaderSpecial}>연</span>예인이다!
							</h1>
						) : (
							<h1 style={stylesM.landingHeader}>Your favorite K-creator!</h1>
						)}

						<h1 style={stylesM.landingSubHeader}>{i18n.t('landingSubHeader')}</h1>
						<Button style={stylesM.landingButton} onClick={openForm}>
							{i18n.t('landingButton')}
						</Button>
					</div>
				</div>
				<Fade bottom>
					<div style={stylesM.companyDescriptionSection}>
						<h1 style={stylesM.companyDescriptionHeader}>{i18n.t('companyDescriptionHeader')}</h1>
						<p style={stylesM.companyDescriptionText}>{i18n.t('companyDescriptionText')}</p>
						<div style={stylesM.linkContainer}>
							<h2 style={stylesM.linkText}>Fan</h2>
							<Lottie
								options={linkOptions}
								height={'100%'}
								width={'100%'}
								isClickToPauseDisabled={true}
							/>
							<h2 style={stylesM.linkText}>Creator</h2>
						</div>
					</div>
				</Fade>
				<div style={stylesM.numbersSection}>
					<div style={stylesM.numbersSectionLeft}>
						<h4 style={stylesM.numbersHeader}>{i18n.t('numbersHeader')}</h4>
						<h1 style={stylesM.numbersSubHeader}>{i18n.t('numbersSubHeader')}</h1>
						<p style={stylesM.numbersParagraph}>{i18n.t('numbersParagraph')}</p>
					</div>
					<div style={stylesM.numbersSectionRight}>
						<Fade right cascade>
							<div className="shadow-lg" style={stylesM.numbersCard}>
								<p style={stylesM.numbersCardValue}>3x</p>
								<p style={stylesM.numbersCardText}>{i18n.t('numbersCardText1')}</p>
							</div>
							<div className="shadow-lg" style={stylesM.numbersCard}>
								<p style={stylesM.numbersCardValue}>90%</p>
								<p style={stylesM.numbersCardText}>{i18n.t('numbersCardText2')}</p>
							</div>
							<div className="shadow-lg" style={stylesM.numbersCard}>
								<p style={stylesM.numbersCardValue}>30%</p>
								<p style={stylesM.numbersCardText}>{i18n.t('numbersCardText3')}</p>
							</div>
						</Fade>
					</div>
				</div>
				<div style={stylesM.specialOfferSection}>
					<div style={stylesM.specialOfferSectionLeft}>
						<h1 style={stylesM.specialOfferHeader}>{i18n.t('specialOfferHeader')}</h1>
						<p style={stylesM.specialOfferParagraph}>{i18n.t('specialOfferParagraph')}</p>
					</div>
					<div style={stylesM.specialOfferSectionRight}>
						<Lottie
							options={coinOptions}
							height={'80%'}
							width={'80%'}
							isClickToPauseDisabled={true}
						/>
					</div>
				</div>
				<div style={stylesM.benefitsSection}>
					<div style={stylesM.benefitsSectionLeft}>
						<h1 style={stylesM.benefitsHeader}>{i18n.t('benefitsHeader')}</h1>
						<p style={stylesM.benefitsParagraph}>{i18n.t('benefitsParagraph1')}</p>
						<p style={stylesM.benefitsParagraph}>{i18n.t('benefitsParagraph2')}</p>
						<li style={stylesM.benefitsParagraph}>{i18n.t('benefitsParagraph3')}</li>
						<li style={stylesM.benefitsParagraph}>{i18n.t('benefitsParagraph4')}</li>
						<li style={stylesM.benefitsParagraph}>{i18n.t('benefitsParagraph5')}</li>
						<li style={stylesM.benefitsParagraph}>{i18n.t('benefitsParagraph6')}</li>
						<li style={stylesM.benefitsParagraph}>{i18n.t('benefitsParagraph7')}</li>
					</div>
					<div style={stylesM.benefitsSectionRight}>
						<video width="100%" height="100%" autoPlay loop muted style={{ borderRadius: 25 }}>
							<source src={CreatorVideo} type="video/mp4" />
						</video>
					</div>
				</div>
				<div style={stylesM.tiersSection}>
					<div style={stylesM.tiersSectionLeft}>
						<h1 style={stylesM.tiersHeader}>{i18n.t('tiersHeader')}</h1>
						<p style={stylesM.tiersParagraph}>{i18n.t('tiersParagraph')}</p>
					</div>
					<div style={stylesM.tiersSectionRight}>
						<Fade right cascade>
							<div className="shadow-lg" style={stylesM.tierCard}>
								<p style={stylesM.tierCardValue}>$5</p>
								<p style={stylesM.tierCardText}>{i18n.t('tierCardText1')}</p>
							</div>
							<div className="shadow-lg" style={stylesM.tierCard}>
								<p style={stylesM.tierCardValue}>$10</p>
								<p style={stylesM.tierCardText}>{i18n.t('tierCardText2')}</p>
							</div>
							<div className="shadow-lg" style={stylesM.tierCard}>
								<p style={stylesM.tierCardValue}>$25</p>
								<p style={stylesM.tierCardText}>{i18n.t('tierCardText3')}</p>
							</div>
						</Fade>
					</div>
				</div>
				<div style={stylesM.communitySection}>
					<h1 style={stylesM.communityHeader}>{i18n.t('communityHeader')}</h1>
					<p style={stylesM.communityDescription}>{i18n.t('communityDescription')}</p>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'space-evenly'
						}}
					>
						<div style={{ width: '100%', borderRadius: 15, background: 'white' }}>
							<Lottie
								options={communityOptions}
								height={'100%'}
								width={'100%'}
								isClickToPauseDisabled={true}
							/>
						</div>
						<div style={{ width: '100%' }}>
							<p style={stylesM.communityRightText}>{i18n.t('communityRightText')}</p>
						</div>
					</div>
				</div>
				<div style={stylesM.supportNetworkSection}>
					<h1 style={stylesM.supportNetworkHeader}>{i18n.t('supportNetworkHeader')}</h1>
					<div style={stylesM.supportNetworkCardContainer}>
						<Fade bottom cascade>
							<img
								src={require('../assets/photos/VIP-X.png')}
								alt="VIPX"
								style={stylesM.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/VIP-C.png')}
								alt="VIPC"
								style={stylesM.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/VentureLab.png')}
								alt="VLAB"
								style={stylesM.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/10KE.png')}
								alt="10KE"
								style={stylesM.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/WhartonEntrepreneurship.png')}
								alt="WhartonEntrepreneurship"
								style={stylesM.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/InnovationFund.png')}
								alt="InnovationFund"
								style={stylesM.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/WeissFund.png')}
								alt="WeissFund"
								style={stylesM.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/JamesJooJin.jpg')}
								alt="JamesJooJin"
								style={stylesM.supportNetworkCard}
								className="shadow"
							/>
							<img
								src={require('../assets/photos/EALC.png')}
								alt="EALC"
								style={stylesM.supportNetworkCard}
								className="shadow"
							/>
						</Fade>
					</div>
				</div>
				<div style={stylesM.belowSupportNetworkSection}>
					<Fade left cascade>
						<div style={stylesM.belowSupportNetworkCard}>
							<h2 style={stylesM.belowSupportNetworkCardText}>
								{i18n.t('belowSupportNetworkCardText1')}
							</h2>
							<img src={require('../assets/photos/Stripe.png')} alt="Stripe" width={150} />
						</div>
					</Fade>
					<Fade right cascade>
						<div style={stylesM.belowSupportNetworkCard}>
							<h2 style={stylesM.belowSupportNetworkCardText}>
								{i18n.t('belowSupportNetworkCardText2')}
							</h2>
							<img src={require('../assets/photos/UPenn.png')} alt="UPenn" width={150} />
						</div>
					</Fade>
				</div>
				<div style={stylesM.reminderSection}>
					<div style={stylesM.reminderSectionLeft}>
						<h1 style={stylesM.reminderLogo}>Wooyeon</h1>
						<p style={stylesM.reminderHeader}>{i18n.t('reminderHeader')}</p>
						<h1 style={stylesM.reminderStatement}>{i18n.t('reminderStatement')}</h1>
						<Button style={stylesM.reminderButton} onClick={openForm}>
							{i18n.t('reminderButton')}
						</Button>
					</div>
					<div style={stylesM.reminderSectionRight}>
						<Lottie
							options={growthOptions}
							height={'80%'}
							width={'80%'}
							isClickToPauseDisabled={true}
						/>
					</div>
				</div>
				<div style={stylesM.footer}>
					<div>
						<p style={stylesM.footerText}>{i18n.t('footerText1')}</p>
						<p style={stylesM.footerText}>{i18n.t('footerText2')}</p>
						<p style={stylesM.footerText}>{i18n.t('footerText3')}</p>
						<div style={stylesM.documentContainer}>
							<p style={stylesM.tos}>{i18n.t('tos')}</p>
							<p style={stylesM.pp}>{i18n.t('pp')}</p>
						</div>
						<p style={stylesM.footerText}>{i18n.t('footerText4')}</p>
					</div>
				</div>
			</div>
		)
	}
}

const styles = {
	screen: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column'
	},
	logo: {
		color: COLORS.wooyeonGreen,
		fontFamily: 'nunito',
		fontWeight: 800,
		position: 'absolute',
		left: '10%',
		top: 15
	},
	landing: {
		paddingLeft: '10%',
		paddingRight: '10%',
		display: 'flex',
		flex: 1,
		minHeight: '100vh',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		backgroundImage: `url(${LandingPhoto})`,
		boxShadow: 'inset 0 0 0 50vw rgba(100,100,100,0.5)',
		backgroundSize: 'cover'
	},
	landingLeft: { display: 'flex', flexDirection: 'column', marginTop: '10%' },
	landingRight: {},
	landingHeader: {
		fontSize: 48,
		fontFamily: 'nunito',
		marginBottom: 60,
		fontWeight: 600,
		color: 'white'
	},
	landingHeaderSpecial: { color: COLORS.wooyeonGreen },
	landingSubHeader: {
		fontSize: 32,
		fontFamily: 'nunito',
		marginBottom: 60,
		fontWeight: 600,
		color: 'white'
	},
	landingButton: {
		backgroundColor: COLORS.wooyeonGreen,
		borderColor: COLORS.wooyeonGreen,
		height: 60,
		width: '50%',
		fontSize: 18,
		fontWeight: 600,
		borderRadius: 15
	},
	companyDescriptionSection: {
		paddingLeft: '20%',
		paddingRight: '20%',
		paddingTop: '10%',
		paddingBottom: '10%',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	companyDescriptionHeader: {
		textAlign: 'center',
		marginBottom: 30,
		// color: 'white',
		fontFamily: 'nunito',
		fontWeight: 'bold',
		fontSize: 48
	},
	companyDescriptionText: {
		textAlign: 'center',
		// color: 'white',
		fontSize: 24,
		fontFamily: 'nunito'
	},
	linkContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	},
	linkText: {
		fontFamily: 'nunito',
		fontWeight: 800
	},
	numbersSection: {
		paddingLeft: '10%',
		paddingRight: '10%',
		display: 'flex',
		flex: 1,
		minHeight: '50vh',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	numbersSectionLeft: { width: '50%' },
	numbersSectionRight: {
		width: '50%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	numbersHeader: { color: COLORS.wooyeonGreen, fontFamily: 'nunito' },
	numbersSubHeader: { fontFamily: 'nunito', fontWeight: 'bold' },
	numbersParagraph: { fontSize: 18, width: '75%', fontFamily: 'nunito' },
	numbersCard: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingLeft: '5%',
		paddingRight: '5%',
		borderRadius: 15,
		width: '90%',
		height: 80,
		marginBottom: 30,
		backgroundColor: 'white'
	},
	numbersCardValue: {
		color: COLORS.wooyeonGreen,
		fontSize: 32,
		fontWeight: 'bold',
		marginRight: 30,
		minWidth: 60,
		fontFamily: 'nunito'
	},
	numbersCardText: {
		fontFamily: 'nunito',
		fontWeight: 600
	},
	specialOfferSection: {
		paddingLeft: '10%',
		paddingRight: '10%',
		display: 'flex',
		flex: 1,
		minHeight: '50vh',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	specialOfferSectionLeft: { width: '50%' },
	specialOfferSectionRight: {
		width: '50%'
	},
	specialOfferHeader: { fontFamily: 'nunito', fontWeight: 'bold' },
	specialOfferParagraph: { fontSize: 18, width: '75%' },
	benefitsSection: {
		paddingLeft: '10%',
		paddingRight: '10%',
		display: 'flex',
		flex: 1,
		minHeight: '80vh',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	benefitsSectionLeft: { width: '50%' },
	benefitsSectionRight: {
		width: '50%',
		justifyContent: 'center',
		display: 'flex'
	},
	benefitsHeader: { fontFamily: 'nunito', fontWeight: 'bold' },
	benefitsParagraph: { fontSize: 18, width: '75%' },
	tiersSection: {
		paddingLeft: '10%',
		paddingRight: '10%',
		display: 'flex',
		flex: 1,
		minHeight: '100vh',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	tiersSectionLeft: { width: '50%' },
	tiersSectionRight: {
		width: '50%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	tiersHeader: { fontFamily: 'nunito', fontWeight: 'bold' },
	tiersParagraph: { fontSize: 18, width: '75%' },
	tierCard: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingLeft: '5%',
		paddingRight: '5%',
		borderRadius: 15,
		width: '70%',
		height: 80,
		marginBottom: 30,
		backgroundColor: 'white'
	},
	tierCardValue: {
		color: COLORS.wooyeonGreen,
		fontSize: 32,
		fontWeight: 'bold',
		marginRight: 60,
		minWidth: 60,
		fontFamily: 'nunito'
	},
	tierCardText: {
		fontFamily: 'nunito',
		fontWeight: 600,
		fontSize: 24
	},
	communitySection: {
		paddingLeft: '10%',
		paddingRight: '10%',
		display: 'flex',
		flex: 1,
		minHeight: '100vh',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	communityHeader: {
		textAlign: 'center',
		marginBottom: 30,
		fontFamily: 'nunito',
		fontWeight: 'bold',
		fontSize: 48
	},
	communityDescription: {
		textAlign: 'center',
		fontSize: 24,
		fontWeight: 400,
		fontFamily: 'nunito',
		paddingLeft: '10%',
		paddingRight: '10%',
		marginBottom: 30
	},
	communityRightText: {
		fontSize: 24,
		fontFamily: 'nunito',
		fontWeight: 'bold'
	},
	supportNetworkSection: {
		paddingTop: '10%',
		paddingLeft: '25%',
		paddingRight: '25%',
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		minHeight: '100vh',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	supportNetworkHeader: {
		marginBottom: 60,
		fontFamily: 'nunito',
		fontWeight: 'bold'
	},
	supportNetworkCardContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		flexWrap: 'wrap'
	},
	supportNetworkCard: {
		width: '25%',
		height: '25%',
		padding: '2%',
		margin: 10,
		borderRadius: 15
	},
	belowSupportNetworkSection: {
		paddingLeft: '20%',
		paddingRight: '20%',
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		minHeight: '50vh',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	belowSupportNetworkCard: {},
	belowSupportNetworkCardText: { fontSize: 16, marginBottom: -50, color: COLORS.wooyeonDarkGrey2 },
	reminderSection: {
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingTop: '10%',
		paddingBottom: '10%',
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: COLORS.lottieGrey
	},
	reminderSectionLeft: { width: '60%' },
	reminderSectionRight: { width: '40%' },
	reminderLogo: { fontFamily: 'nunito', fontWeight: 800, color: COLORS.wooyeonGreen },
	reminderHeader: { fontFamily: 'nunito', fontSize: 18 },
	reminderStatement: { fontFamily: 'nunito', fontWeight: 'bold', fontSize: 48, marginBottom: 60 },
	reminderButton: {
		backgroundColor: COLORS.wooyeonGreen,
		borderColor: COLORS.wooyeonGreen,
		height: 60,
		width: '50%',
		fontSize: 18,
		fontWeight: 600,
		borderRadius: 15
	},
	footer: {
		border: '1px solid rgba(0, 0, 0, 0.1)',
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingTop: '5%',
		paddingBottom: '5%',
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		// justifyContent: 'center',
		// alignItems: 'center',
		backgroundColor: COLORS.lottieGrey
	},
	footerText: {
		fontSize: 14,
		fontFamily: 'nunito'
	},
	documentContainer: { display: 'flex', flexDirection: 'row' },
	tos: { fontSize: 14, fontFamily: 'nunito', textDecoration: 'underline' },
	pp: { fontSize: 14, fontFamily: 'nunito', marginLeft: 15, textDecoration: 'underline' }
}

const stylesM = {
	screen: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column'
	},
	logo: {
		color: COLORS.wooyeonGreen,
		fontFamily: 'nunito',
		fontWeight: 800,
		position: 'absolute',
		left: '10%',
		top: 15
	},
	landing: {
		paddingLeft: '10%',
		paddingRight: '10%',
		display: 'flex',
		flex: 1,
		minHeight: '100vh',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		backgroundImage: `url(${LandingPhoto})`,
		boxShadow: 'inset 0 0 0 50vw rgba(100,100,100,0.5)',
		backgroundSize: 'cover',
		backgroundPosition: '50% 0%'
	},
	landingLeft: { display: 'flex', flexDirection: 'column', marginTop: '80%' },
	landingRight: {},
	landingHeader: {
		fontSize: 48,
		fontFamily: 'nunito',
		marginBottom: 30,
		fontWeight: 600,
		color: 'white'
	},
	landingHeaderSpecial: { color: COLORS.wooyeonGreen },
	landingSubHeader: {
		fontSize: 32,
		fontFamily: 'nunito',
		marginBottom: 60,
		fontWeight: 600,
		color: 'white'
	},
	landingButton: {
		backgroundColor: COLORS.wooyeonGreen,
		borderColor: COLORS.wooyeonGreen,
		height: 60,
		width: '80%',
		fontSize: 18,
		fontWeight: 600,
		borderRadius: 15
	},
	companyDescriptionSection: {
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingTop: '30%',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	companyDescriptionHeader: {
		marginBottom: 30,
		fontFamily: 'nunito',
		fontWeight: 'bold',
		fontSize: 48
	},
	companyDescriptionText: {
		fontSize: 24,
		fontFamily: 'nunito'
	},
	linkContainer: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'center'
	},
	linkText: {
		fontFamily: 'nunito',
		fontWeight: 800,
		fontSize: 18
	},
	numbersSection: {
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingTop: '20%',
		paddingBottom: '20%',
		display: 'flex',
		flex: 1,
		minHeight: '100vh',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	numbersSectionLeft: { width: '100%', marginBottom: 30 },
	numbersSectionRight: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	numbersHeader: { color: COLORS.wooyeonGreen, fontFamily: 'nunito' },
	numbersSubHeader: { fontFamily: 'nunito', fontWeight: 'bold' },
	numbersParagraph: { fontSize: 18, width: '100%', fontFamily: 'nunito' },
	numbersCard: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingLeft: '5%',
		paddingRight: '5%',
		borderRadius: 15,
		width: '100%',
		height: 100,
		marginBottom: 30,
		backgroundColor: 'white'
	},
	numbersCardValue: {
		color: COLORS.wooyeonGreen,
		fontSize: 32,
		fontWeight: 'bold',
		marginRight: 30,
		minWidth: 60,
		fontFamily: 'nunito'
	},
	numbersCardText: {
		fontFamily: 'nunito',
		fontWeight: 600
	},
	specialOfferSection: {
		paddingLeft: '10%',
		paddingRight: '10%',
		display: 'flex',
		flex: 1,
		minHeight: '70vh',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	specialOfferSectionLeft: { width: '100%' },
	specialOfferSectionRight: {
		width: '100%'
	},
	specialOfferHeader: { fontFamily: 'nunito', fontWeight: 'bold' },
	specialOfferParagraph: { fontSize: 18, width: '100%' },
	benefitsSection: {
		paddingLeft: '10%',
		paddingRight: '10%',
		display: 'flex',
		flex: 1,
		minHeight: '80vh',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	benefitsSectionLeft: { width: '100%', marginBottom: 30 },
	benefitsSectionRight: {
		width: '100%',
		justifyContent: 'center',
		display: 'flex'
	},
	benefitsHeader: { fontFamily: 'nunito', fontWeight: 'bold' },
	benefitsParagraph: { fontSize: 18, width: '100%' },
	tiersSection: {
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingTop: '20%',
		paddingBottom: '20%',
		display: 'flex',
		flex: 1,
		minHeight: '100vh',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	tiersSectionLeft: { width: '100%', marginBottom: 30 },
	tiersSectionRight: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	tiersHeader: { fontFamily: 'nunito', fontWeight: 'bold' },
	tiersParagraph: { fontSize: 18, width: '75%' },
	tierCard: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingLeft: '5%',
		paddingRight: '5%',
		borderRadius: 15,
		width: '100%',
		height: 100,
		marginBottom: 30,
		backgroundColor: 'white'
	},
	tierCardValue: {
		color: COLORS.wooyeonGreen,
		fontSize: 32,
		fontWeight: 'bold',
		marginRight: 60,
		minWidth: 60,
		fontFamily: 'nunito'
	},
	tierCardText: {
		fontFamily: 'nunito',
		fontWeight: 600,
		fontSize: 24
	},
	communitySection: {
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingTop: '20%',
		paddingBottom: '20%',
		display: 'flex',
		flex: 1,
		minHeight: '80vh',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	communityHeader: {
		marginBottom: 30,
		fontFamily: 'nunito',
		fontWeight: 'bold',
		fontSize: 48
	},
	communityDescription: {
		fontSize: 24,
		fontWeight: 400,
		fontFamily: 'nunito'
	},
	communityRightText: {
		fontSize: 24,
		fontFamily: 'nunito',
		fontWeight: 'bold'
	},
	supportNetworkSection: {
		paddingLeft: '2%',
		paddingRight: '2%',
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		minHeight: '80vh',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white'
	},
	supportNetworkHeader: {
		marginBottom: 60,
		fontFamily: 'nunito',
		fontWeight: 'bold'
	},
	supportNetworkCardContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		flexWrap: 'wrap'
	},
	supportNetworkCard: {
		width: '28%',
		height: '28%',
		padding: '2%',
		margin: 5,
		borderRadius: 15
	},
	belowSupportNetworkSection: {
		paddingLeft: '10%',
		paddingRight: '10%',
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		flexDirection: 'column',
		backgroundColor: 'white'
	},
	belowSupportNetworkCard: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
	belowSupportNetworkCardText: { fontSize: 16, marginBottom: 0, color: COLORS.wooyeonDarkGrey2 },
	reminderSection: {
		paddingLeft: '5%',
		paddingRight: '5%',
		paddingTop: '10%',
		paddingBottom: '10%',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: COLORS.lottieGrey
	},
	reminderSectionLeft: { width: '100%', marginBottom: 30 },
	reminderSectionRight: { width: '100%' },
	reminderLogo: { fontFamily: 'nunito', fontWeight: 800, color: COLORS.wooyeonGreen },
	reminderHeader: { fontFamily: 'nunito', fontSize: 18 },
	reminderStatement: { fontFamily: 'nunito', fontWeight: 'bold', fontSize: 24, marginBottom: 30 },
	reminderButton: {
		backgroundColor: COLORS.wooyeonGreen,
		borderColor: COLORS.wooyeonGreen,
		height: 60,
		width: '80%',
		fontSize: 18,
		fontWeight: 600,
		borderRadius: 15
	},
	footer: {
		border: '1px solid rgba(0, 0, 0, 0.1)',
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingTop: '5%',
		paddingBottom: '5%',
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		// justifyContent: 'center',
		// alignItems: 'center',
		backgroundColor: COLORS.lottieGrey
	},
	footerText: {
		fontSize: 14,
		fontFamily: 'nunito'
	},
	documentContainer: { display: 'flex', flexDirection: 'row' },
	tos: { fontSize: 14, fontFamily: 'nunito', textDecoration: 'underline' },
	pp: { fontSize: 14, fontFamily: 'nunito', marginLeft: 15, textDecoration: 'underline' }
}

export default Home
